<div class="content-wrapper">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-0">
        <div class="col-sm-6">
        </div>
      </div>
    </div>
  </section>

  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Static Contents</h3>
              <!-- <div class="card-tools">
                <div class="input-group input-group-sm" style="width: 200px;">
                  <input type="text" name="table_search" class="form-control float-right" placeholder="Search" [(ngModel)]="searchKey">
                  <div class="input-group-append">
                    <button type="button" class="btn btn-default" (click)="SearchStaticContents()">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="card-body table-responsive p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Page Type</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let staticPage of staticPages; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{staticPage?.pageType}}</td>
                    <td>{{staticPage?.createdAt | date}}</td>
                    <td>
                      <a href="javascript:void(0)" [routerLink]="['/staticPages', staticPage.staticContentId]"><i class="fas fa-eye text-info"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div *ngIf="staticPages.length == 0">
                <p class="text-center">StaticPages list not found</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
