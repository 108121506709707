<div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <!-- <button type="button" class="btn btn-info float-right" routerLink="/addMetatag">Add Meta Tag</button> -->
          </div>
        </div>
      </div>
    </section>
  
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Careers</h3>
                <div class="card-tools">
                  <div class="input-group input-group-sm" style="width: 200px;">
                    <input type="text" name="table_search" class="form-control float-right" placeholder="Search" [(ngModel)]="searchKey">
                    <div class="input-group-append">
                      <button type="button" class="btn btn-default" (click)="SearchMetatags()">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table class="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile Number</th>
                      <th>Address</th>
                      <th>City</th>
                      <th>State</th>
                      <th>Zip</th>
                      <th>Qualification</th>
                      <th>Job experience</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let metatag of metatags | paginate: { itemsPerPage: perPage, currentPage: pageIndex, totalItems: metatagsCount }; let i = index">
                      <td>{{i+1}}</td>
                      <td>{{metatag?.name}}</td>
                      <td>{{metatag?.email}}</td>
                      <td>{{metatag?.mobile_number}}</td>
                      <td>{{metatag?.address}}</td>
                      <td>{{metatag?.city}}</td>
                      <td>{{metatag?.state}}</td>
                      <td>{{metatag?.zip}}</td>
                      <td>{{metatag?.qua}}</td>
                      <td>{{metatag?.job_ex}}</td>
                      <td>
                        <a href="javascript:void(0)" (click)="showPdf(metatag?.image)"><i class="fas fa-download text-info"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div *ngIf="metatags.length == 0">
                  <p class="text-center">Careers list not found</p>
                </div>
              </div>
              <div class="custom_pagination mt-4" *ngIf="metatags?.length > 0">
                <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  