<div class="content-wrapper">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <!-- <h1>Change Password</h1> -->
        </div>
      </div>
    </div>
  </section>

  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Change Password</h3>
            </div>
            <form [formGroup]="ChangePasswordForm" novalidate>
              <div class="card-body">
                <div class="form-group">
                  <label>Current Password</label>
                  <div class="input-group mb-3">
                    <input [type]="currentPwdLockFlag ? 'text' : 'password'" class="form-control" placeholder="Current Password" id="currentPassword" name="currentPassword" formControlName="currentPassword">
                    <div class="input-group-append">
                      <div class="input-group-text" (click)="ShowHideCurrentPassword()">
                        <span class="fas fa-lock" *ngIf="currentPwdLockFlag === false"></span>
                        <span class="fas fa-unlock" *ngIf="currentPwdLockFlag"></span>
                      </div>
                    </div>
                  </div>
                  <span class="errorMsg" *ngIf="isChangePasswordSubmit && ChangePasswordForm.controls.currentPassword.hasError('required')">Kindly enter current password</span>
                </div>
                <div class="form-group">
                  <label>New Password</label>
                  <div class="input-group mb-3">
                    <input [type]="newPwdLockFlag ? 'text' : 'password'" class="form-control" placeholder="New Password" id="newPassword" name="newPassword" formControlName="password">
                    <div class="input-group-append">
                      <div class="input-group-text" (click)="ShowHideNewPassword()">
                        <span class="fas fa-lock" *ngIf="newPwdLockFlag === false"></span>
                        <span class="fas fa-unlock" *ngIf="newPwdLockFlag"></span>
                      </div>
                    </div>
                  </div>
                  <span class="errorMsg" *ngIf="isChangePasswordSubmit && ChangePasswordForm.controls.password.hasError('required')">Kindly enter a password</span>
                  <span class="errorMsg" *ngIf="isChangePasswordSubmit && ChangePasswordForm.controls.password.hasError('minLength')">Password should be in 8 to 15 characters</span>
                  <span class="errorMsg" *ngIf="isChangePasswordSubmit && ChangePasswordForm.controls.password.hasError('maxLength')">Password should be in 8 to 15 characters</span>
                </div>
                <div class="form-group">
                  <label>Confirm Password</label>
                  <div class="input-group mb-3">
                    <input [type]="cnfPwdLockFlag ? 'text' : 'password'" class="form-control" placeholder="Confirm Password" id="confirmPassword" name="confirmPassword" formControlName="confPassword">
                    <div class="input-group-append">
                      <div class="input-group-text" (click)="ShowHideConfirmPassword()">
                        <span class="fas fa-lock" *ngIf="cnfPwdLockFlag === false"></span>
                        <span class="fas fa-unlock" *ngIf="cnfPwdLockFlag"></span>
                      </div>
                    </div>
                  </div>
                  <span class="errorMsg" *ngIf="isChangePasswordSubmit && ChangePasswordForm.controls.confPassword.hasError('required')">Kindly enter confirm password</span>
                  <span class="errorMsg" *ngIf="isChangePasswordSubmit && ChangePasswordForm.controls.confPassword.hasError('MatchPassword')">Password not matched</span>
                </div>
              </div>
              <div class="card-footer">
                <button type="submit" class="btn btn-primary" (click)="ChangePassword(ChangePasswordForm.value)">Submit</button>
                <button type="button" class="btn btn-secondary ml-2" routerLink="/home">Cancel</button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6"></div>
      </div>
    </div>
  </section>
</div>