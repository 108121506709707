<nav class="main-header navbar navbar-expand navbar-white navbar-light">
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" data-widget="pushmenu" href="javascript:void(0)" role="button"><i class="fas fa-bars"></i></a>
    </li>
  
    <li class="nav-item">
      <a class="nav-link">CURRENCY {{currency}}</a>
    </li>
  </ul>

  <ul class="navbar-nav ml-auto">
    <li class="nav-item dropdown">
      <a class="nav-link" data-toggle="dropdown" href="javascript:void(0)">
        <i class="fas fa-user-cog"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <!-- <a href="javascript:void(0)" class="dropdown-item">
          <div class="media">
            <div class="media-body">
              <h3 class="dropdown-item-title" routerLink="/profile">
                Profile
                <span class="float-right text-sm text-muted"><i class="fas fa-id-badge"></i></span>
              </h3>
            </div>
          </div>
        </a>
        <div class="dropdown-divider"></div>-->
        <a href="javascript:void(0)" class="dropdown-item">
          <div class="media">
            <div class="media-body">
              <h3 class="dropdown-item-title" routerLink="/changePassword">
                Change Password
                <span class="float-right text-sm text-muted"><i class="fas fa-key"></i></span>
              </h3>
            </div>
          </div>
        </a>
        <div class="dropdown-divider"></div>
        <a href="javascript:void(0)" class="dropdown-item">
          <!-- Message Start -->
          <div class="media">
            <div class="media-body">
              <h3 class="dropdown-item-title" (click)="Logout()">
                Logout
                <span class="float-right text-sm text-danger"><i class="fas fa-sign-out-alt"></i></span>
              </h3>
            </div>
          </div>
          <!-- Message End -->
        </a>
      </div>
    </li>
  </ul>
</nav>