import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CareerService } from './career.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent implements OnInit {

  metatags: any;
  pageIndex: number = 1;
  perPage: number = 10;
  searchKey: string;
  metatagsCount: number = 0;

  constructor(private careerService: CareerService, private toastr: ToastrService, private ngxLoader: NgxUiLoaderService) { }

  ngOnInit(): void {
    this.GetMetatags()
  }

  // Get all Metatags
  GetMetatags () {
    let reqObj = {
      pageIndex: (this.pageIndex - 1),
      perPage: this.perPage,
      searchKey: this.searchKey
    }
    this.metatags = [];
    this.ngxLoader.start();
    this.careerService.GetCareers(reqObj).then((result) => {
      this.ngxLoader.stop();
      this.metatags = result['data']
      this.metatagsCount = result['totalCount']
    }).catch((err) => {
      this.ngxLoader.stop();
      console.log(err);
      this.toastr.error(err.error.message, 'Failed');
    });
  }

  // Search Metatags
  SearchMetatags () {
    this.pageIndex = 1;
    this.perPage = 10;
    this.GetMetatags();
  }
  showPdf(pdf) {
    console.log(pdf);
    
    const linkSource = 'data:application/pdf;base64,' +pdf;
    const downloadLink = document.createElement("a");
    const fileName = "sample.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}

  pageChange(e) {
    this.pageIndex = e;
    this.perPage = this.perPage;
    this.searchKey = this.searchKey;
    this.GetMetatags();
  }

  // Active/Inactive Metatag
  

}
