<div class="content-wrapper">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <button type="button" class="btn btn-info float-right" routerLink="/addCoupon">Add New Discount Coupon</button>
        </div>
      </div>
    </div>
  </section>

  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">Discount Coupons</h3>
              <div class="card-tools">
                <div class="input-group input-group-sm" style="width: 200px;">
                  <input type="text" name="table_search" class="form-control float-right" placeholder="Search" [(ngModel)]="searchKey">
                  <div class="input-group-append">
                    <button type="button" class="btn btn-default" (click)="SearchCoupons()">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body table-responsive p-0">
              <table class="table table-hover text-nowrap">
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Value</th>
                    <th>Expired Date</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let coupon of coupons | paginate: { itemsPerPage: perPage, currentPage: pageIndex, totalItems: couponsCount }; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{coupon?.name}}</td>
                    <td>{{coupon?.type}}</td>
                    <td>${{coupon?.value}}</td>
                    <td>{{coupon?.expiredDate | date}}</td>
                    <td>{{coupon?.createdAt | date}}</td>
                    <td>
                      <!-- <a href="javascript:void(0)" [routerLink]="['/coupon', coupon.couponId]"><i class="fas fa-edit text-info"></i></a> -->
                      <a href="javascript:void(0)">
                        <i (click)="ChangeCouponStatus(coupon.stripeCouponId,2)" title="Inactive Blog" class="fas fa-times-circle ml-3 text-danger"></i>

                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div *ngIf="coupons.length == 0">
                <p class="text-center">Coupons list not found</p>
              </div>
            </div>
            <div class="custom_pagination mt-4" *ngIf="coupons?.length > 0">
              <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
