<div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <button type="button" class="btn btn-info float-right" routerLink="/addMetatag">Add Meta Tag</button>
          </div>
        </div>
      </div>
    </section>
  
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Meta Tags</h3>
                <div class="card-tools">
                  <div class="input-group input-group-sm" style="width: 200px;">
                    <input type="text" name="table_search" class="form-control float-right" placeholder="Search" [(ngModel)]="searchKey">
                    <div class="input-group-append">
                      <button type="button" class="btn btn-default" (click)="SearchMetatags()">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table class="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Tag</th>
                      <th>Type</th>
                      <th>blog Title</th>
                      <th>Position</th>
                      <th>Created At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let metatag of metatags | paginate: { itemsPerPage: perPage, currentPage: pageIndex, totalItems: metatagsCount }; let i = index">
                      <td>{{i+1}}</td>
                      <td>{{metatag?.tags}}</td>
                      <td>{{metatag?.type}}</td>
                      <td>{{metatag?.blog_detalis}}</td>
                      <td>{{metatag?.position}}</td>
                      <td>{{metatag?.createdAt | date}}</td>
                      <td>
                        <a href="javascript:void(0)" [routerLink]="['/metatag', metatag.id]"><i class="fas fa-edit text-info"></i></a>
                        <a href="javascript:void(0)">
                          <i *ngIf="metatag.status == 1" (click)="ChangeMetatagStatus(metatag.id, 2)" title="Inactive Metatag" class="fas fa-times-circle ml-3 text-danger"></i>
                          <i *ngIf="metatag.status == 2" (click)="ChangeMetatagStatus(metatag.id, 1)" title="Active Metatag" class="fas fa-check-circle ml-3 text-success"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div *ngIf="metatags.length == 0">
                  <p class="text-center">Metatags list not found</p>
                </div>
              </div>
              <div class="custom_pagination mt-4" *ngIf="metatags?.length > 0">
                <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  