<div class="wrapper">
  <app-header></app-header>

  <app-sidebar></app-sidebar>

  <router-outlet></router-outlet>

  <app-footer></app-footer>
  <div class="control-sidebar-bg"></div>
</div>
