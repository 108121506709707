<aside class="main-sidebar sidebar-dark-primary elevation-4">
  <!-- Brand Logo -->
  <a href="javascript:void(0)" class="brand-link">
    <img src="./assets/img/app_logo.png" alt="AdminLTE Logo" class="brand-image img-fluid rounded-circle"
      style="opacity: .8">
    <span class="brand-text font-weight-light">247LiveIt</span>
  </a>

  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <li class="nav-item">
          <a href="javascript:void(0)" class="nav-link" routerLink="/home">
            <i class="nav-icon fas fa-tachometer-alt"></i>
            <p>Dashboard</p>
          </a>
        </li>
        <li class="nav-item">
          <a href="javascript:void(0)" class="nav-link" routerLink="/users">
            <i class="nav-icon fas fa-users"></i>
            <p>Users</p>
          </a>
        </li>
        <li class="nav-item">
          <a href="javascript:void(0)" class="nav-link" routerLink="/packages">
            <i class="nav-icon fas fa-money-check"></i>
            <p>Subscription Packages</p>
          </a>
        </li>
        <li class="nav-item">
          <a href="javascript:void(0)" class="nav-link" routerLink="/staticPages">
            <i class="nav-icon fas fa-file-alt"></i>
            <p>Static Page</p>
          </a>
        </li>
        <li class="nav-item">
          <a href="javascript:void(0)" class="nav-link" routerLink="/testimonials">
            <i class="nav-icon fas fa-newspaper"></i>
            <p>Testimonials</p>
          </a>
        </li>
        <li class="nav-item">
          <a href="javascript:void(0)" class="nav-link" routerLink="/coupons">
            <i class="nav-icon fas fa-tags"></i>
            <p>Discount Coupons</p>
          </a>
        </li>
        <li class="nav-item">
          <a href="javascript:void(0)" class="nav-link" routerLink="/metatag">
            <i class="nav-icon fas fa-code"></i>
            <p>Meta Tags</p>
          </a>
        </li>
        <li class="nav-item">
          <a href="javascript:void(0)" class="nav-link" routerLink="/blog">
            <i class="nav-icon fas fa-list-alt"></i>
            <p>Blog</p>
          </a>
        </li>
        <li class="nav-item">
          <a href="javascript:void(0)" class="nav-link" routerLink="/career">
            <i class="nav-icon fas fa-graduation-cap"></i>
            <p>Career</p>
          </a>
        </li>
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>